export const subBanners = [
  {
    page: '/About',
    bannerImgUrl: '/images/about/subBanner.jpg',
    breadcrumb: 'About',
  },
  {
    page: '/OurServices',
    bannerImgUrl: '/images/ourservices/subBanner.jpg',
    breadcrumb: 'Our Services',
  },
  {
    page: '/TrainingAndCoaching',
    bannerImgUrl: '/images/trainingandcoaching/subBanner.jpg',
    breadcrumb: 'Training & Coaching',
  },
  {
    page: '/FAQs',
    bannerImgUrl: '/images/faqs/subBanner.jpg',
    breadcrumb: 'FAQs',
  },
  {
    page: '/Contact',
    bannerImgUrl: '/images/contact/subBanner.jpg',
    breadcrumb: 'Contact',
  },
  {
    page: '/BookWithUs',
    bannerImgUrl: '/images/bookwithus/subBanner.jpg',
    breadcrumb: 'Book With Us',
  },
  {
    page: '/PrivacyPolicy',
    bannerImgUrl: '/images/about/subBanner.jpg',
    breadcrumb: 'Privacy Policy',
  },

  {
    page: '/TermsAndConditions',
    bannerImgUrl: '/images/about/subBanner.jpg',
    breadcrumb: 'Terms And Conditions',
  },
];
