export const ourServices = [
  {
    id: 1,
    itemClass: 'services-item-1',
    image: '/images/homepage/ourservices/consulting.jpg',
    serviceName: 'Consulting',
    serviceText:
      'New to the short-term rental business and looking for guidance from experienced managers and hosts?',
    linkLoc: '/OurServices',
    altAtt: '',
  },
  {
    id: 2,
    itemClass: 'services-item-2',
    image: '/images/homepage/ourservices/listingSetUp.jpg',
    serviceName: 'Listing Set-up',
    serviceText:
      'Create an Airbnb profile + complete listing set up & ranking optimization.',
    linkLoc: '/',
    altAtt: '',
  },
  {
    id: 3,
    itemClass: 'services-item-3',
    image: '/images/homepage/ourservices/propertyManagement.jpg',
    serviceName: 'Rental Management',
    serviceText:
      'Manage the day to day communications for inquiries and confirmed guests.',
    linkLoc: '/',
    altAtt: '',
  },
  {
    id: 4,
    itemClass: 'services-item-4',
    image: '/images/homepage/ourservices/travelCoordination.jpg',
    serviceName: 'Travel Coordination',
    serviceText:
      'Besides accommodations, we have travel pros that can help you book your perfect tour/trip around the world.',
    linkLoc: '/',
    altAtt: '',
  },
  {
    id: 5,
    itemClass: 'services-item-5',
    image: '/images/homepage/ourservices/financing.jpg',
    serviceName: 'Financing',
    serviceText:
      'Looking to invest into a vacation home for short term rental but need help with financing?',
    linkLoc: '/',
    altAtt: '',
  },
];
