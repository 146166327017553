import styled from 'styled-components';

export const MainSectionWrapper = styled.section`
  h2 {
    font-size: 2.3rem;
    line-height: 2.5rem;
    font-weight: bold;
  }

  h3 {
    font-size: 1.17rem;
    font-weight: bold;
  }

  h2,
  h3,
  p {
    margin-block-end: 1.25rem;
  }

  a {
    margin-left: 5px;
    text-decoration: underline;
  }
`;

export const List = styled.ul<{ $listType?: string }>`
  list-style: ${({ $listType }) => $listType ?? 'none'};
  padding-left: 1.5rem;
  margin-bottom: 1.5rem;
`;
