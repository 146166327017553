import React from 'react';
import { Link, useLocation } from 'react-router-dom';

// Components and Styles
import { MainSectionWrapper, List } from './pageStyles';
import './About.css';
import MainNav from '../components/MainNav';
import SubBanner from '../components/SubBanner';
import Footer from './Footer';

const PrivacyPolicy = () => {
  const location = useLocation();

  return (
    <>
      <MainNav />
      <SubBanner bannerUrl={location.pathname} />
      <div className="content-wrapper">
        <MainSectionWrapper className="section-wrapper">
          <h2>Privacy Policy</h2>
          <p>
            At www.shortstaysintl.com your privacy is among our highest
            priorities.
          </p>
          <p>
            With the internet age, online shopping has become a fast, easy and
            convenient way to shop. Our goal is to provide you, the customer,
            with the highest level of customer service, the best value, and
            quality merchandise. We use information from you and other parties
            to help us obtain this goal.
          </p>
          <p>
            We, at shortstaysintl.com, respect your privacy, as well as
            recognize the sensitivity of the issue. We have implemented policies
            intended to ensure your personal information is safely handled in a
            responsible and secure manner.
          </p>
          <h3>Your Collected Information</h3>
          <p>
            Any and every time you visit our website, our Web server
            automatically collects some non-personal, identifiable information,
            such as your Internet service provider’s address and information on
            the pages you access on our site. From your transactions and other
            interactions with us, we obtain information concerning the specific
            products or services you purchase or use.
          </p>
          <p>
            We also collect personally identifiable information that you
            voluntarily provide, such as survey information and information you
            provide when you make a purchase or register your e-mail address.
          </p>
          <h3>How we use your information</h3>
          <p>The information we collect is used:</p>
          <List $listType="disc">
            <li>To provide assistance and/or services you request;</li>
            <li>
              To contact you about the status and/or details of your
              livingyourbestlifewithgrace.com orders;
            </li>
            <li>
              To improve the content and layout of our Website, our merchandise
              selections, customer service, overall customer shopping
              experience, and the overall web site satisfaction
            </li>
            <li>
              To notify you about updates to our Web site or for marketing
              purposes
            </li>
            <li>
              To identify your product and service preferences, so that you can
              be informed of new or additional products, services, and
              promotions that might be of interest to you;
            </li>
            <li>
              Shared, in some circumstances, with affiliate organizations for
              marketing purposes.
            </li>
          </List>
          <p>
            When you supply us with personal information on-line to request a
            catalog or order a product, the information you provide will be
            added to our customer file. You may receive occasional mailings or
            emails with information on new products and services, discounts,
            special promotions or upcoming events that might be of interest to
            you.
          </p>
          <p>
            If you do not wish to receive such e-mails or mailings in the
            future, please send us an e-mail requesting to be taken off the
            list.
          </p>
          <p>
            We protect against unauthorized disclosures by limiting access only
            to those of our employees who need the information to do their jobs.
            Our employees are well informed of their responsibility to protect
            our customers’ privacy and they are given clear guidelines for
            adhering to our own business ethics standards and confidentiality
            policies.
          </p>
          <p>
            In some instances, we may use a third party to help us in providing
            our products and services to you. Some of these selected vendors are
            given access to some or all of the information you provide to us,
            including your e-mail address, and may use cookies on our behalf.
            These companies are restricted from using your information in any
            manner, other than in aiding us to provide you with the services and
            products available on our site.
          </p>
          <p>
            We limit the information we provide to our third-party
            vendors/companies by only releasing the information we believe is
            appropriate to enable them to carry out their responsibilities under
            our contracts or to offer you products and services we think might
            be of interest to you. We require that the information provided be
            used only for the specifically authorized purpose and that its
            confidentiality is maintained.
          </p>
          <h3>Cookies: Purpose and Usage</h3>
          <p>
            Most Internet sites, including ours, collect data about visitors by
            using “cookies”. Cookies are small software programs that help sites
            recognize repeat visitors by assigning a random number to each
            computer/IP address for tracking content preferences, traffic
            patterns, and other census information. We use cookies to connect
            your computer with the information we store for you at a separate
            location. Some of the information includes your account preferences,
            items added to your shopping cart, user-specific information on
            pages accessed and previous visits to our site. We do not use
            cookies to store any of your personal or financial information on
            your computer. If you wish to purchase from our site, you must
            accept the cookie so that we can safely and securely process your
            order.
          </p>
          <h3>Information Security</h3>
          <p>
            When you see either a locked padlock icon or a solid key icon at the
            bottom portion of your browser window, then the site you are viewing
            is secured through Secure Sockets Layer. We always use
            industry-standard encryption technologies when transferring and
            receiving your credit card information on our site. If you do not
            see one of these icons, please contact your Internet service
            provider. We have appropriate security measures in place in our
            facilities to protect against the misuse, alteration or loss of
            information that we have collected from you at our site so you can
            feel secure and at ease while doing business with shortstaysintl.com
          </p>
          <h3>Updating Your Information</h3>
          <p>
            You can contact us to update or correct your personal information,
            check your transaction information, and/or the status of your order
            at info@shortstaysintl.com . Upon receiving any request, we will
            evaluate each request and reply in an appropriate, timely manner and
            form of communication that ensures the security of the information
            you have requested.
          </p>
          <h3>Further Information</h3>
          <p>
            It is recommended that you review this Privacy Policy Statement
            periodically as we may update it from time to time. This policy was
            last revised on January 1st, 2023. When considering any changes or
            edits, your privacy will remain a top priority. If you would like
            further information or have any questions please email us at
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = `mailto:info@shortstaysintl.com`;
                e.preventDefault();
              }}
            >
              info@shortstaysintl.com
            </Link>
            .
          </p>
        </MainSectionWrapper>
      </div>
      <Footer />
    </>
  );
};
export default PrivacyPolicy;
