import React from 'react';
import { Link, useLocation } from 'react-router-dom';

// Components and Styles
import { MainSectionWrapper, List } from './pageStyles';
import './About.css';
import MainNav from '../components/MainNav';
import SubBanner from '../components/SubBanner';
import Footer from './Footer';

const TermsAndConditions = () => {
  const location = useLocation();

  return (
    <>
      <MainNav />
      <SubBanner bannerUrl={location.pathname} />
      <div className="content-wrapper">
        <MainSectionWrapper className="section-wrapper">
          <h2>Terms and Conditions</h2>
          <p>
            This Privacy Policy/Terms and Conditions governs the manner in which
            Short Stays International collects, uses, maintains and discloses
            information collected from users (each, a “User”) of the
            www.shortstaysintl.com website (“Site”). This privacy policy applies
            to the Site and all products and services offered by Short Stays
            International.
          </p>
          <h3>Personal identification information</h3>
          <p>
            We may collect personal identification information from Users in a
            variety of ways, including, but not limited to when Users visit our
            site, register on the site, and fill out a form, and in connection
            with other activities, services, features or resources we make
            available on our Site. Users may be asked for, as appropriate, name,
            email address, mailing address, phone number, credit card
            information. Users may, however, visit our Site anonymously.
          </p>
          <p>
            We will collect personal identification information from Users only
            if they voluntarily submit such information to us. Users can always
            refuse to supply personally identification information, except that
            it may prevent them from engaging in certain Site related
            activities.
          </p>
          <h3>Non-personal identification information</h3>
          <p>
            We may collect non-personal identification information about Users
            whenever they interact with our Site. Non-personal identification
            information may include the browser name, the type of computer and
            technical information about Users means of connection to our Site,
            such as the operating system and the Internet service providers
            utilized and other similar information.
          </p>
          <h3>Web browser cookies</h3>
          <p>
            Our Site may use “cookies” to enhance User experience. User’s web
            browser places cookies on their hard drive for record-keeping
            purposes and sometimes to track information about them. User may
            choose to set their web browser to refuse cookies or to alert you
            when cookies are being sent. If they do so, note that some parts of
            the Site may not function properly.
          </p>
          <h3>How we use collected information</h3>
          <p>
            Short Stays Internationl collects and uses Users personal
            information for the following purposes:
          </p>
          <List $listType="disc">
            <li>
              To improve customer service: your information helps us to more
              effectively respond to your customer service requests and support
              needs.
            </li>
            <li>
              To improve our Site: We continually strive to improve our website
              offerings based on the information and feedback we receive from
              you.
            </li>
            <li>
              To process transactions: We may use the information Users provide
              about themselves when placing an order only to provide service to
              that order. We do not share this information with outside parties
              except to the extent necessary to provide the service.
            </li>
            <li>
              To send periodic emails to the email address users provide for
              order processing, will only be used to send them information and
              updates pertaining to their order. It may also be used to respond
              to their inquiries, and/or other requests or questions. If User
              decides to opt-in to our mailing list, they will receive emails
              that may include company news, updates, related product or service
              information, etc. If at any time the User would like to
              unsubscribe from receiving future emails, we include detailed
              unsubscribe instructions at the bottom of each email or User may
              contact us via our Site.
            </li>
          </List>
          <h3>How we protect your information</h3>
          <p>
            We adopt appropriate data collection, storage, and processing
            practices and security measures to protect against unauthorized
            access, alteration, disclosure or destruction of your personal
            information, username, password, transaction information and data
            stored on our Site.
          </p>
          <p>
            Sensitive and private data exchange between the Site and its Users
            happens over an SSL secured communication channel and is encrypted
            and protected with digital signatures.
          </p>
          <h3>Changes to this privacy policy</h3>
          <p>
            Short Stays International has the discretion to update this privacy
            policy at any time. When we do, we will post a notification on the
            main page of our Site, or revise the “updated” date at the bottom of
            this page. We encourage Users to frequently check this page for any
            changes to stay informed about how we are helping to protect the
            personal information we collect. You acknowledge and agree that it
            is your responsibility to review this privacy policy periodically
            and become aware of modifications.
          </p>
          <h3>Your acceptance of these terms</h3>
          <p>
            By using this Site, you signify your acceptance of this policy and
            terms of service. If you do not agree to this policy, please do not
            use our Site. Your continued use of the Site following the posting
            of changes to this policy will be deemed your acceptance of those
            changes.
          </p>
          <h3>Contacting us</h3>
          <p>
            If you have any questions about this Privacy Policy, the practices
            of this site, or your dealings with this site, please contact us at:
          </p>
          <p>
            Short Stays International
            <br />
            <Link to="/Contact">www.shortstaysintl.com</Link>
            <br />
            <Link
              to="#"
              onClick={(e) => {
                window.location.href = `mailto:info@shortstaysintl.com`;
                e.preventDefault();
              }}
            >
              info@shortstaysintl.com
            </Link>
          </p>
        </MainSectionWrapper>
      </div>
      <Footer />
    </>
  );
};
export default TermsAndConditions;
